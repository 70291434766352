import Routes from "./Routes"
import AudioRecordingImage from "./images/audioRecording.jpeg"
import TranscriptionImage from "./images/transcription.jpeg"
import TranslationImage from "./images/translation.jpeg"
import DataCurationImage from "./images/dataCuration.jpeg"


export const servicesInHomePage = [
    {
        id: 1,
        title: "Audio Recording",
        description: "Read Speech recording or extempore audio recording by the native people based on the requirements of language, dialect, gender, age of the speaker, expressive story reading, and the recording environment of the audio.",
        image: AudioRecordingImage,
        link: Routes.audioRecording
    },
    {
        id: 2,
        title: "Transcription",
        description: "Transcription of audio by people native to the language into accurate, readable text.",
        image: TranscriptionImage,
        link: Routes.transcription
    },
    {
        id: 3,
        title: "Translation",
        description: "Comprehensive text curation and enhancing and translating text with the help of language experts for various applications.",
        image: TranslationImage,
        link: Routes.translation
    },
    {
        id: 4,
        title: "Custom Data Curation",
        description: " We meticulously curate custom datasets in both audio and text to ensure accuracy and relevance for your AI models.",
        image: DataCurationImage,
        link: Routes.customDataCuration
    }
]


export const associatedServices = [
    {
        title: "Audio Segmentation",
        description: "Dividing audio into meaningful segments for analysis."
    },
    {
        title: "Audio Verification",
        description: "Ensuring integrity and authenticity of audio data."
    },
    {
        title: "Image Annotations",
        description: "Detailed image data annotations."
    },
    {
        title: "NLP Annotations",
        description: "Data Curation for NLPs project requirements."
    }
]

//colour enlisting
export const alternatingBackgroundColour = "ButtonHighlight"
export const openingBackgroundColour = 'linear-gradient(0deg,#D3D3D3,#D3D3D3, #76c7f2 ), linear-gradient(to bottom, transparent,#D3D3D3,#D3D3D3,#D3D3D3)'
export const cardBackgroundColour1 = 'linear-gradient(to right,#5da9e6,#d4a9fc ), linear-gradient(to bottom, transparent,#dde5df,#dde5df,#dde5df )'
export const cardBackgroundColour2 = 'linear-gradient(to right,#5da9e6,#d4a9fc,#5da9e6 ), linear-gradient(to bottom, transparent,#dde5df,#dde5df,#dde5df )'
export const buttonColour = "#1976d2"
export const buttonColourHovering = "#07145f"



export const design1 = {
    display: "flex",
    height: 'auto',
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "7%"
}

export const design2 = {
    minHeight: { xs: "500px", lg: "550px" },
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    position: 'relative',
    overflow: 'hidden',
}

export const design3 = {
    fontSize: {
        xs: '2.5em',
        sm: '3rem',
        md: '3.25rem',
        lg: '3.5rem',
        xl: '3.75rem',
    },
    fontWeight: 'bold',
    textAlign: 'center'
}

export const design4 = {
    display: 'flex',
    flexDirection: {
        xs: 'column',
        sm: 'column',
        md: 'row',
    },
    alignItems: 'center',
    justifyContent: 'center',
    background: cardBackgroundColour1,
    backgroundBlendMode: 'overlay',
    padding: 4,
    width: { xs: "85%", sm: "80%", lg: "85%" },
    minHeight: "500px",
    height: 'auto',
    marginTop: { xs: "40px", md: "30px" },
    marginBottom: { xs: "40px", md: "30px" },
    borderRadius: "15px",
    '@media (max-width: 450px)': {
        width: '80%'
    }
}

export const design5 = {
    maxWidth: { xs: '100%', md: '50%' },
    marginBottom: { xs: 2, md: 0 },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

export const design6 = {
    fontWeight: 'bold',
    display: 'block',
    textAlign: 'center',
    fontSize: {
        xs: '2rem',
        sm: '2.5rem',
        md: '2.75rem',
        lg: '3rem',
        xl: '3.5rem',
    },
    margin: "2%"
}

export const design7 = {
    fontSize: {
        xs: '1.25rem',
        sm: '1.3rem',
        md: '1.35rem',
        lg: '1.4rem',
        xl: '1.5rem',
    },
    display: 'block',
    margin: { xs: '1.5%', md: '1%' },
    textAlign: 'center',
}

export const design8 = {
    mt: "1%",
    mb: "3%",
    backgroundColor: buttonColour,
    borderRadius: '5px',
    '&:hover': {
        backgroundColor: buttonColourHovering,
    },
}

export const design9 = {
    position: 'relative',
    width: { xs: '100%', md: '50%' },
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mt: { xs: "2%", sm: '1.5%' },
    mb: { xs: 3, md: 0 }
}

export const design10 = {
    maxWidth: { xs: '80%', sm: "70%", md: '75%' },
    zIndex: 1,
    borderRadius: '10%',
    margin: "5%",
    padding: "2%",
    border: "5px solid black",
    '@media (max-width: 450px)': {
        maxWidth: '90%'
    }
}

export const design11 = {
    width: { xs: '90%', sm: "80%", md: '85%', lg: "100%", xl: "90%" },
    height: { xs: "175px", sm: "150px", md: "175px", lg: "225px", xl: '175px' },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: "2%",
    marginBottom: "1%",
    background: "none",
    border: "3.5px solid black",
    borderRadius: "15px",
    boxShadow: "none",
    textAlign: "center"
}