
const Routes = {
    base: "/",
    home: "/home",
    vaniAnnotations: "/services/vani-annotations",
    vaniStudio: "/services/vani-studio",
    transcription: "/services/transcription",
    audioRecording: "/services/audio-recording",
    translation: "/services/translation",
    customDataCuration: "/services/custom-data-curation",
    otherAssociatedServices: "/services/other-associated-services",
    participateAndEarn: "/participate-and-earn",
    aboutUs: "/about-us",
    contact: "/contact",
    signIn: "/sign-in",
    register: "/register",
    forgotPassword: "/forgot-password",
    resetPassword: "/reset-password",
    termsOfUse: "/terms-of-use",
    privacyPolicy: "/privacy-policy",
    account: "/account",
    admin: "/admin",
    payment: '/payment',

    seco: "/seco",
    traco: "/traco",
    cott: "/cott",

    qgen: "/qgen",
    post: "/post",
    enta: "/enta",
    tran: "/tran",
    autex: "/autex",
    audver: "/audver",
    imtex: "/imtex"
}

export default Routes;