//import { Link } from 'react-router-dom';
import { Container, Typography, Grid, Box, Card, CardContent, CardMedia } from '@mui/material'
//import Routes from '../../../../constants/Routes';
import ParticipateAndEarnImage from '../../../../constants/images/participateAndEarn.jpeg'
import { alternatingBackgroundColour, design1, design2, design3, design7 } from '../../../../constants/landingPageInfo';

function ParticipateAndEarn() {
    return (
        <>
            <Box
                sx={{
                    ...design1,
                    backgroundColor: alternatingBackgroundColour
                }}>

                <Box
                    sx={design2} >


                    <Typography
                        sx={{
                            ...design3,
                            '@media (max-width: 460px)': {
                                fontSize: '1.75rem'
                            }
                        }}
                    >

                        <span style={{ whiteSpace: 'nowrap' }}>
                            Be Part of Something Big: </span>
                        <br />
                        <span style={{ whiteSpace: 'nowrap' }}>Start Annotating Today!</span>
                    </Typography>

                </Box>


                <Container>
                    <Grid container spacing={0} justifyContent="center"
                        sx={{
                            marginTop: "2%",
                        }}
                    >
                        <Grid item sm={12} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'center', marginTop: "30px" }}>
                            <Card
                                sx={{
                                    width: '100%', height: "100%", padding: { xs: '10px', md: '20px' }, margin: '0px', boxShadow: "none", background: "none", display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }} >

                                <CardMedia
                                    component="img"
                                    image={ParticipateAndEarnImage}
                                    alt="participate-and-earn-image"
                                    sx={{
                                        width: { xs: "90%", sm: "70%", md: "100%", lg: "90%" }, borderRadius: "50px",
                                        margin: '0 auto'
                                    }}

                                />


                            </Card>
                        </Grid>

                        <Grid item sm={12} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Card sx={{
                                width: '100%', height: "100%", padding: { xs: "0px", sm: '10px', md: "0px", lg: "20px" }, marginTop: { xs: 0, md: "5%", lg: "2.5%", xl: "1.5%" }, boxShadow: "none", background: "none", display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',

                            }}  >



                                <CardContent style={{ marginTop: "5%" }}>
                                    <Typography
                                        gutterBottom
                                        component="div"
                                        sx={{
                                            margin: { xs: "2.5%", md: 0, lg: "2%" },
                                            fontSize: {
                                                xs: '2rem',
                                                sm: '2.25rem',
                                                md: '2.25rem',
                                                lg: '2.5rem',
                                                xl: '3rem',
                                            },
                                            textAlign: 'center',
                                            fontWeight: "bold"
                                        }}
                                    >
                                        Participate and Earn
                                    </Typography>
                                    <Typography
                                        sx={{

                                            ...design7, textAlign: "justify"
                                        }}>
                                        It is our small initiative to employ India. In India everyone is strong at his/her mothertongue. By sharing their knowledge and language skills for the development of their own language, they can earn money. Through this process students, unemployed, senior citizens, house-wives can earn some, that may help them to become financially independent.
                                    </Typography>
                                </CardContent>

                                {/* <Button
                                    variant="contained"
                                    sx={design8}
                                    component={Link}
                                    to={Routes.participateAndEarn}
                                >
                                    Learn More
                                </Button> */}



                            </Card>

                        </Grid>
                    </Grid>
                </Container>
            </Box >
        </>
    )
}

export default ParticipateAndEarn