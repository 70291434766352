export enum Roles 
{
    GUEST=0,
    REGISTERED=101,
    ANNOTATOR=201,
    PROJECTOWNER=301,
    REVIEWER=401,
    VALIDATOR=501,
    MANAGER=601,
    GROUPADMIN=701,
    BUSINESSADMIN=801,
    ADMIN=901
}
