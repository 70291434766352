import { ProjectActions } from "../reducerActions/ProjectActions";

const initialState = {
    loadingProjects:false,
    loadingAnnotators:false,
    annotators:undefined,
    candidates:undefined,
    guidelines:undefined,
    projects:undefined
}

const ProjectReducer = (state=initialState,action) => {

    const newState ={...state};
    
    if(action.type === ProjectActions.PROJECTS_LIST){
        newState.projects = action.projects;
        newState.loadingProjects = false;
    }
    else if(action.type === ProjectActions.SAVE_ANNOTATORS){
        newState.annotators = action.annotators;
        newState.loadingAnnotators = false;
    }
    else if(action.type === ProjectActions.CANDIDATE_LIST){
        newState.candidates = action.candidates;
    }

    else if(action.type === ProjectActions.RESET){
        newState.loadingAnnotators=false;
        newState.annotators=undefined;
        newState.candidates=undefined;
        newState.guidelines = undefined;
    }
    else if(action.type === ProjectActions.SAVE_GUIDELINES){
        newState.guidelines = action.guidelines;
    }
    return newState;
}

export default ProjectReducer;