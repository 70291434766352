import { Stack, Box } from "@mui/material"
import { Link } from "react-router-dom";
import Routes from "../constants/Routes";
import { appInsights } from "../helpers/AppInsights";

function logo(){
    return <Box
                component="img"
                alt="vaniverse"
                sx={{
                    height:{xs:40,md:48},
                    pl:1
                }}
                src={window.location.origin +'/VaniVerseLogo.png'}/>
}

export function logoTitle()
{
    return(
        <Link to={Routes.base}
            onClick={()=>appInsights.trackEvent({name:'Page logo clicked',properties:{component:'LogoButton'}})}
            style={{ textDecoration: 'none',cursor: "pointer"}}
              >
            <Stack direction='row' 
            alignItems='center' 
            justifyContent='center'
            >
              {logo()}
             {/* <Typography variant='h1' color='primary' sx={{fontSize:{xs:40,md:48},fontWeight:'bold',px:2}}>VaniVerse</Typography> */}
            </Stack>
        </Link>)
    
}