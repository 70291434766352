import { Box, Typography } from "@mui/material"
import IndianMap from "./IndianMap"
import IndianMapMobile from "./IndianMapMobile";
import { isMobile, isDesktop } from 'react-device-detect';
//import { cardBackgroundColour2 } from "../../../../constants/landingPageInfo"

function LanguagesWithMap() {
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    minHeight: { xs: "550px", lg: "550px" },
                    height: 'auto',
                    flexDirection: {
                        xs: 'column',
                        sm: 'column',
                        md: 'row',
                    },
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "2%",
                    paddingBottom: "5%",
                    overflow: 'auto',
                    // background: cardBackgroundColour2

                }}
            >

                <Box
                    sx={{

                        width: { xs: '100%', md: '50%' },
                        marginBottom: { xs: '2%', md: 0 }

                    }}
                >
                    <Typography

                        sx={{
                            fontSize: {
                                xs: '2.5em',
                                sm: '3rem',
                                md: '3.25rem',
                                lg: '3.5rem',
                                xl: '3.75rem',
                            },
                            fontWeight: 'bold',
                            textAlign: 'center',
                            marginBottom: { xs: '5%', md: 0 },
                            '@media (max-width: 375px)': {
                                fontSize: '1.75rem'
                            },
                            '@media (min-width: 375px) and (max-width: 450px)': {
                                fontSize: '2rem'
                            },
                            '@media (min-width: 900px) and (max-width: 1050px)': {
                                fontSize: '2.9rem'
                            },

                        }}
                    >
                        <span style={{ whiteSpace: 'nowrap' }}>Our focus is on</span>
                        <br />
                        <span style={{ whiteSpace: 'nowrap' }}>
                            Indian Languages</span>
                    </Typography>

                </Box>
                <Box sx={{
                    width: { xs: '100%', md: '50%' }
                }}>
                    {isMobile && !isDesktop ? <IndianMapMobile /> : <IndianMap />}
                </Box>
            </Box>
        </>
    )
}

export default LanguagesWithMap