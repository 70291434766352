//import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
//import Routes from '../../../../constants/Routes';
import { servicesInHomePage, associatedServices, alternatingBackgroundColour, design1, design2, design3, design4, design5, design6, design7, design9, design10, design11 } from '../../../../constants/landingPageInfo';


const ServicesHome = () => {


    return (
        <>
            <Box
                sx={{
                    ...design1,
                    backgroundColor: alternatingBackgroundColour
                }}>

                <Box sx={design2} >

                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1.5 }}
                        viewport={{ once: false, amount: 0.8 }}
                    >
                        <Typography
                            sx={design3}
                        >

                            <span style={{ whiteSpace: 'nowrap' }}>Human Annotations in</span>
                            <br />
                            <span style={{ whiteSpace: 'nowrap' }}>
                                Indian Languages</span>
                        </Typography>
                    </motion.div>
                </Box>

                {
                    [0, 1, 2, 3].map((ele, i) => {
                        return (
                            [0, 2].includes(ele) ?
                                (<Box
                                    sx={design4}
                                    key={i}
                                >
                                    <Box
                                        sx={design5}
                                    >

                                        <Typography
                                            gutterBottom
                                            component="div"
                                            sx={design6}
                                        >
                                            {servicesInHomePage[ele].title}
                                        </Typography>

                                        <Typography
                                            variant="body1"
                                            sx={design7}
                                        >
                                            {servicesInHomePage[ele].description}
                                        </Typography><br />
                                        {/* <Button
                                            variant="contained"
                                            sx={design8}
                                            component={Link}
                                            to={servicesInHomePage[ele].link}
                                        
                                        >
                                            View More
                                        </Button> */}

                                    </Box>
                                    <Box
                                        sx={design9}
                                    >

                                        <Box
                                            component="img"
                                            src={servicesInHomePage[ele].image}
                                            alt="service-image"
                                            sx={design10}
                                        />

                                    </Box>
                                </Box>
                                ) : (


                                    <Box
                                        sx={design4}
                                        key={i}
                                    >

                                        <Box
                                            sx={design9}
                                        >
                                            <Box
                                                component="img"
                                                src={servicesInHomePage[ele].image}
                                                alt="service-image"
                                                sx={design10}
                                            />
                                        </Box>

                                        <Box
                                            sx={design5}
                                        >

                                            <Typography
                                                gutterBottom

                                                component="div"
                                                sx={design6}
                                            >
                                                {servicesInHomePage[ele].title}
                                            </Typography>

                                            <Typography
                                                variant="body1"
                                                sx={design7}
                                            >
                                                {servicesInHomePage[ele].description}
                                            </Typography><br />
                                            {/* <Button
                                                variant="contained"
                                                sx={design8}
                                                component={Link}
                                                to={servicesInHomePage[ele].link}
                                            
                                            >
                                                View More
                                            </Button> */}

                                        </Box>


                                    </Box>

                                )

                        )
                    })
                }

                {/* For Other Services */}

                <Box
                    sx={{ ...design4, minHeight: "550px" }}

                >
                    <Box
                        sx={{ ...design5, maxWidth: { xs: '100%', md: '100%' }, marginBottom: { xs: 2, md: 2 }, marginTop: { md: 1.5 } }}
                    >

                        <Typography
                            gutterBottom
                            variant="h2"
                            component="div"
                            sx={{
                                ...design6,
                                margin: "1%",

                            }}
                        >
                            Other Associated Services
                        </Typography>


                        {/* Presenting as Box */}

                        <Grid container spacing={5} justifyContent="center" sx={{ mt: "1.5%", mb: "1.5%" }}>
                            {
                                associatedServices.map((ele, i) => {
                                    return (
                                        <Grid item sm={12} md={6} lg={3} key={i} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Card
                                                sx={design11}
                                            >

                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div"
                                                        sx={{ fontWeight: "bold" }}
                                                    >
                                                        {ele.title}
                                                    </Typography>
                                                    <Typography variant="h6" >
                                                        {ele.description}
                                                    </Typography>
                                                </CardContent>


                                            </Card>

                                        </Grid>
                                    )
                                }
                                )}
                        </Grid>


                        <br />
                        {/* <Button
                            variant="contained"
                            sx={design8}
                            component={Link}
                            to={Routes.otherAssociatedServices}
                        >
                            View More
                        </Button> */}

                    </Box>

                </Box>
            </Box>
        </>
    );
};

export default ServicesHome;