//import { Link } from "react-router-dom";
import { Typography, Box } from "@mui/material"
import { motion } from 'framer-motion';
//import Routes from "../../../../constants/Routes";
import VaniStudioImage from "../../../../constants/images/providing-platform.jpg"
import { cardBackgroundColour2, design1, design2, design3, design4, design5, design6, design7, design9, design10 } from "../../../../constants/landingPageInfo";

function ProvidingPlatform() {
    return (
        <div >
            <Box
                sx={design1}>

                <Box
                    sx={design2} >

                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1.5 }}
                        viewport={{ once: false, amount: 0.8 }}
                    >
                        <Typography
                            sx={{
                                ...design3,
                                '@media (max-width: 460px)': {
                                    fontSize: '1.75rem'
                                }
                            }}
                        >
                            <span style={{ whiteSpace: 'nowrap' }}>A space for you to host</span>
                            <br />
                            <span style={{ whiteSpace: 'nowrap' }}>And create your project</span>
                            {/* <br />
                            A space for you to host
                            <br />
                            And create your project
                            <br /> */}

                        </Typography>
                    </motion.div>
                </Box>

                <Box
                    sx={{
                        ...design4,
                        background: cardBackgroundColour2

                    }}
                >
                    <Box sx={{
                        ...design5, maxWidth: { xs: '100%', md: '55%' }
                    }}>
                        <Typography
                            component="div"
                            sx={design6}>
                            Vani Studio
                        </Typography>

                        <Typography
                            variant="body1"
                            sx={design7}
                        >
                            We offer a robust platform where you can host and manage your own data curation projects, streamlining your workflow, managing annotators, and enhancing productivity.
                        </Typography><br />
                        {/* <Button
                            variant="contained"
                            sx={design8}
                            component={Link}
                            to={Routes.vaniStudio}
                            
                        >
                            View More
                        </Button> */}

                    </Box>

                    <Box
                        sx={{
                            ...design9,
                            width: { xs: '100%', md: '45%' },

                        }}
                    >
                        <Box
                            component="img"
                            src={VaniStudioImage}
                            alt="providing-platform"
                            sx={design10}
                        />

                    </Box>
                </Box>

            </Box>
        </div>
    )
}

export default ProvidingPlatform