import { useEffect } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Container, Typography, Grid, Card, Box, useMediaQuery, useTheme } from '@mui/material';
import Routes from '../../constants/Routes';
import { appInsights, reactPlugin } from '../../helpers/AppInsights';
import SignInCard from '../register/SignInCard';
import LanguagesWithMap from './subComponents/homePage/LanguagesWithMap';
import ServicesHome from './subComponents/homePage/ServicesHome';
import ServicesHomeSmall from './subComponents/homePage/ServicesHomeSmall';
import ProvidingPlatform from './subComponents/homePage/ProvidingPlatform';
import ParticipateAndEarn from "./subComponents/homePage/ParticipateAndEarn";
import ContactUsPage from './ContactUsPage';
import { openingBackgroundColour } from '../../constants/landingPageInfo'




export interface IHomePageProps {
}

export interface IHomePageState {
}


function HomePage(props: IHomePageProps) {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    appInsights.trackPageView({
      name: 'HomePage',
      uri: Routes.home
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div >

      <Box
        sx={{
          background: openingBackgroundColour,
          backgroundBlendMode: 'overlay',
          minHeight: "500px",
          height: 'auto',
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: { xs: '0px', sm: '10px' },
          overflowY: "auto"
        }}>

        <Container>
          <Grid container spacing={0} justifyContent="center" >
            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Card
                sx={{
                  width: '100%', height: "auto", padding: '0px', marginTop: { xs: "30px", md: "100px" },
                  marginBottom: { xs: '20px', md: 0 }, border: 'none', boxShadow: 'none', background: "none"
                }}
              >
                <Typography
                  color='primary'
                  sx={{
                    fontSize: {
                      xs: '2.5rem',
                      sm: '3rem',
                      md: '3.25rem',
                      lg: '3.5rem',
                      xl: '3.75rem',
                    },
                    fontWeight: 'bold',
                    marginBottom: "2%",
                    textAlign: "center",
                    '@media (max-width: 375px)': {
                      fontSize: '2rem'
                    },
                    '@media (min-width: 375px) and (max-width: 450px)': {
                      fontSize: '2.25rem'
                    },
                    '@media (min-width: 900px) and (max-width: 1050px)': {
                      fontSize: '2.8rem'
                    },
                  }}
                >
                  <span style={{ whiteSpace: 'nowrap' }}>Data that speaks,</span>
                  <br />
                  <span style={{ whiteSpace: 'nowrap' }}>
                    Text that resonates</span>
                </Typography>

                <Typography

                  sx={{
                    fontSize: {
                      xs: '1.5rem',
                      sm: '2rem',
                      md: '2rem',
                      lg: '2rem',
                      xl: '2rem',
                    },
                    textAlign: "center",
                    '@media (max-width: 385px)': {
                      fontSize: '1.25rem'
                    },
                    '@media (min-width: 385px) and (max-width: 450px)': {
                      fontSize: '1.4rem'
                    },
                    '@media (min-width: 900px) and (max-width: 1050px)': {
                      fontSize: '1.7rem'
                    },
                  }}
                >
                  <span style={{ whiteSpace: 'nowrap' }}>Empower your AI model with</span><br /> <span style={{ whiteSpace: 'nowrap' }}>meticulously curated speech and</span><br />
                  <span style={{ whiteSpace: 'nowrap' }}>text data for Indian Languages.</span>
                </Typography>



              </Card>

            </Grid>
            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Card sx={{

                width: 'auto',
                height: 'auto',
                border: 'none',
                boxShadow: 'none',
                background: "none",
                padding: "10px",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                transform: { xs: 'scale(0.9)', sm: 'scale(.8)' },
                transformOrigin: 'top center',
                paddingTop: { xs: '5%', md: '8%' }
              }}
              >
                <SignInCard />

              </Card>

            </Grid>
          </Grid>
        </Container>

      </Box >

      <LanguagesWithMap />
      {!isSmallScreen ? <ServicesHome /> : <ServicesHomeSmall />}

      <ProvidingPlatform />
      <ParticipateAndEarn />

      <Box sx={{ marginTop: "50px", marginBottom: "50px" }}>
        <ContactUsPage />
      </Box>



    </div >
  )
}


export default withAITracking(reactPlugin, HomePage);